import { Component } from '@angular/core';
import { LOADING_MESSAGE } from '@models/loading-message.map';
import { LangCode } from '@models';

@Component({
  selector: 'apo-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.scss']
})
export class LoadingSpinnerComponent {
  get loadingText() {
    const langCode = location.pathname.slice(1, 3) as LangCode;

    return LOADING_MESSAGE.get(langCode) || LOADING_MESSAGE.get('en');
  }
}
