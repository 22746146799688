import { APP_INITIALIZER } from '@angular/core';
import { setCookie } from '@helpers/cookie.helper';

export const cookieInitializerFactory = () =>
  () => {
    if (!cookieFromUrl('cookieConsent')) {
      return;
    }

    const cookieConsent = JSON.parse(cookieFromUrl('cookieConsent'));

    if (cookieConsent?.allowedCookies) {
      cookieConsent.isCookiePolicyAccepted = true;
      setCookie('cookieConsent', JSON.stringify(cookieConsent));
    }
  };

function cookieFromUrl(type): string {
  return new URL(location.href).searchParams.get(type);
}

export const cookieInitializer = {
  provide: APP_INITIALIZER,
  useFactory: cookieInitializerFactory,
  multi: true,
  deps: []
};
