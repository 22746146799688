import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IEnvironment } from '@models';
import { EnvironmentLoadService } from '../services/environment-load.service';

export class TranslatesLoaderService implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private env: EnvironmentLoadService<IEnvironment>
  ) {}

  getTranslation(lang: string): Observable<any> {
    if (!this.env.environment.STORES) return of(null);

    const langId = this.env.environment.STORES.find(l => l.region === lang).language_id;

    return new TranslateHttpLoader(this.http, '/consultation/public/translation/', '?cache=1')
      .getTranslation(langId.replace('-', '_'));
  }
}
