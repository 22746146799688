import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutHeaderComponent } from './checkout-header/checkout-header.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [CheckoutHeaderComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [CheckoutHeaderComponent]
})
export class CheckoutHeaderModule { }
