import { LangCode } from './settings-language';

export const LOADING_MESSAGE = new Map<LangCode, string>([
  ['de', 'Bitte haben Sie ein wenig Geduld.'],
  ['ch', 'Bitte haben Sie ein wenig Geduld.'],
  ['dk', 'Vent venligst et øjeblik.'],
  ['se', 'Var god vänta ett ögonblick.'],
  ['pt', 'Por favor espere um momento.'],
  ['en', 'Please wait a moment.'],
  ['nl', 'Een ogenblik geduld alstublieft.'],
]);
