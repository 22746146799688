import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { IEnvironment } from '@models';
import { StoreInterface } from '@models/store-interface';
import { EnvironmentLoadService } from '../services/environment-load.service';

@Injectable()
export class LanguageService {
  currentLanguage$: BehaviorSubject<string> = new BehaviorSubject(null);

  currentStore: StoreInterface;

  constructor(
    private translateService: TranslateService,
    private environmentLoadService: EnvironmentLoadService<IEnvironment>
  ) {}

  setLanguage() {
    const { websiteConfigs } = window as any;

    const region = websiteConfigs.region;

    this.translateService.use(region);

    this.currentLanguage$.next(region);

    this.currentStore = this.environmentLoadService.environment.currentStore;
  }

}
