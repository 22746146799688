import { APP_INITIALIZER } from '@angular/core';
import { LanguageService } from './language.service';

const factory = (languageService: LanguageService) => () => {
  languageService.setLanguage();
};

export const languageInitializer = {
  provide: APP_INITIALIZER,
  useFactory: factory,
  deps: [LanguageService],
  multi: true
};
