import { APP_INITIALIZER } from '@angular/core';
import { EnvironmentLoadService } from '../services/environment-load.service';
import * as Sentry from '@sentry/angular';

import { environment } from '@env/environment';
import { version } from '@env/version';

export const sentryInitializerFactory = (environmentLoadService: EnvironmentLoadService) => () => {
  if (!environment.production) return;

  Sentry.init({
    dsn: environmentLoadService.environment.SENTRY_DSN,
    attachStacktrace: true,
    release: `${version}`,
    initialScope: {
      tags: {
        'application': environmentLoadService.environment.APPLICATION
      }
    }
  });
};

export const sentryInitializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: sentryInitializerFactory,
  multi: true,
  deps: [EnvironmentLoadService]
};
