import { ErrorHandler } from '@angular/core';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/browser';

class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(err: any): void {
    Sentry.captureException(err.originalError || err);

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(err.message)) {
      window.location.reload();
    }
  }
}

export const browserErrorHandlerProvider = {
  provide: ErrorHandler,
  useClass: environment.production ? SentryErrorHandler : ErrorHandler,
  deps: []
};
