import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isNewUrl } from '@helpers/new-flow-matcher.function';
import { EnvironmentLoadService } from '../services/environment-load.service';
import { IEnvironment } from '@models';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(
    private loadService: EnvironmentLoadService<IEnvironment>
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('/consultation') || request.url.startsWith('/cms') || isNewUrl(request.url)) {
      const newRequest = request.clone({
        url: this.loadService.environment.apiUrl  + request.url
      });

      return next.handle(newRequest);
    }

    return next.handle(request);
  }
}
