import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from '@layout/layout.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxsModule } from '@ngxs/store';
import { environment } from '@env/environment';
import { TranslatesModule } from './shared/intranationalization/translates.module';
import { KitModule } from '@apomeds/shared/ui/kit';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    MatProgressBarModule,
    MatDialogModule,
    TranslatesModule,
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    environment.plugins,
    CommonModule,
    KitModule
  ],
  declarations: [
    AppComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule {
}
