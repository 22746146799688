import { APP_INITIALIZER } from '@angular/core';
import { version } from '@env/version';

const factory = () => () => {
  window['appBuildVersion'] = version;
};

export const buildVersionInitializer = {
  provide: APP_INITIALIZER,
  useFactory: factory,
  deps: [],
  multi: true
};
