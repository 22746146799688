import { APP_INITIALIZER } from '@angular/core';
import { loadScript } from '@helpers';
import { IEnvironment } from '@models';
import { EnvironmentLoadService } from '../services/environment-load.service';
import { getIfExistCookie } from '@helpers/cookie.helper';

const googleAnalyticsInitializerFactory = (env: EnvironmentLoadService<IEnvironment>) =>
  () => {
    const cookieConsent = getIfExistCookie('cookieConsent');

    if (cookieConsent && cookieConsent?.isCookiePolicyAccepted) {
      return cookieConsent?.allowedCookies?.includes('statistic') ? initAnalytic() : '';
    }

    window.addEventListener('policyAccepted', (event: CustomEvent) => {
      if (event.detail?.includes('statistic')) {
        initAnalytic();
      }
    });

    function initAnalytic() {
      setCookiesFromQuery();

      window['gtag'] = window['gtag'] || function() {
        window['dataLayer'].push(arguments);
      };

      window['gtag']('js', [new Date()]);

      return new Promise(resolve => {
        loadScript('https://www.googletagmanager.com/gtag/js?id=' + env.environment.googleAnalyticKey)
          .then(() => {
            window['gtag']('config', [env.environment.googleAnalyticKey], {
              'linker': {
                'accept_incoming': true
              }
            });
            resolve(null);
          })
          .catch(() => resolve(null));
      });
    }
  };

function setCookiesFromQuery() {
  const date = (new Date(Date.now() + 86400e3)).toUTCString();

  document.location.search.slice(1).split('&')
    .filter(x => x.startsWith('_gid=') || x.startsWith('_ga='))
    .forEach(x => document.cookie = x + `; expires=${date}`);
}

export const googleAnalyticsInitializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: googleAnalyticsInitializerFactory,
  deps: [EnvironmentLoadService],
  multi: true
};
