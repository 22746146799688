import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';

import { TranslatesLoaderService } from './translates-loader.service';
import { EnvironmentLoadService } from '../services/environment-load.service';
import { LanguageService } from './language.service';
import { languageInitializer } from './language.initializer';

import localeDe from '@angular/common/locales/de';
import localePl from '@angular/common/locales/pl';
import localeCh from '@angular/common/locales/de-CH';
import localePt from '@angular/common/locales/pt';
import localeSe from '@angular/common/locales/sv';
import localeDk from '@angular/common/locales/da';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeCh, 'ch');
registerLocaleData(localePl, 'pl');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeSe, 'se');
registerLocaleData(localeDk, 'dk');

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslatesLoaderService,
        deps: [HttpClient, EnvironmentLoadService]
      }
    })
  ],
  exports: [
    TranslateModule
  ],
  providers: [
    LanguageService,
    languageInitializer
  ]
})
export class TranslatesModule {
}
