import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppModule } from './app.module';
import { AppComponent } from './app.component';

import * as SmoothScroll from 'smooth-scroll';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import {
  googleAnalyticsInitializerProvider,
  gtmInitializerProvider,
  sentryInitializerProvider
} from './shared/initializers';
import { browserErrorHandlerProvider } from './shared/providers';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseUrlInterceptor } from './shared/interceptors';
import { localeProvider } from './shared/providers/locale.provider';
import { hotJarInitializerProvider } from './shared/initializers/hot-jar.initializer';
import { trackerSdkInitializerProvider } from './shared/initializers/tracker-sdk.initializer';
import { SdkLoggingService } from './shared/services/sdk-logging.service';
import { buildVersionInitializer } from './shared/initializers/build-version.initializer';
import { RetryRequestInterceptor } from './shared/interceptors/retry-request.interceptor';
import { translateCookieInitializerProvider } from './shared/initializers/translate-cookie.initializer';
import { cookieInitializer } from './shared/initializers/cookie.initializer';

@NgModule({
  imports: [
    AppModule,
    BrowserAnimationsModule,
    NgxGoogleAnalyticsModule.forRoot('UA-130488528-1'), // TODO: change to use configuration in proper way
    NgxGoogleAnalyticsRouterModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    cookieInitializer,
    buildVersionInitializer,
    trackerSdkInitializerProvider,
    googleAnalyticsInitializerProvider,
    gtmInitializerProvider,
    sentryInitializerProvider,
    browserErrorHandlerProvider,
    hotJarInitializerProvider,
    translateCookieInitializerProvider,
    SdkLoggingService,
    { provide: 'ORIGIN_URL', useValue: window.location.origin },
    { provide: 'SCROLLING', useValue: new SmoothScroll() },
    // { provide: HTTP_INTERCEPTORS, useClass: AcceptLanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
    localeProvider
  ]
})
export class AppBrowserModule {
}
