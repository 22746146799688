import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { applyPolyfills, defineCustomElements } from './assets/ui-components/loader';

import { AppBrowserModule } from './app/app.browser.module';
import { environment } from '@env/environment';

if (environment.production) {
  enableProdMode();
}
import 'svgxuse';

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch(err => console.error(err));
}, { passive: true });

applyPolyfills().then(() => defineCustomElements());
