import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { IEnvironment, LangCode } from '@models';
import { EnvironmentLoadService } from './shared/services/environment-load.service';

@Injectable({
  providedIn: 'root'
})
export class LangGuard implements CanActivate {

  constructor(private router: Router, private environmentLoadService: EnvironmentLoadService<IEnvironment>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const validLanguages: LangCode[] = this.environmentLoadService.environment.STORES.map(item => item.region);

    const newLanguage: LangCode = route.params.language as LangCode;

    if (!validLanguages.includes(newLanguage)) {
      this.router.navigate(['en', '404']);
      return false;
    }

    return true;
  }
}
