import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';
import { SdkLoggingService } from '../services/sdk-logging.service';

@Injectable()
export class RetryRequestInterceptor implements HttpInterceptor {
  private retryCount = 5;
  private retryWaitMilliSeconds = 1000;

  constructor(private sdkLoggingService: SdkLoggingService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen(error =>
        error.pipe(
          concatMap((_error, count) => {
            if (count <= this.retryCount && _error.status === 0) {
              this.sdkLoggingService.logEvent(`Attempt ${this.retryCount} of retrying`, _error);

              return of(_error);
            }

            if(_error.status === 0) {
              this.sdkLoggingService.logEvent(`Exceed limit of retries on Error 0`, { err: _error });
            }

            return throwError(_error);
          }),
          delay(this.retryWaitMilliSeconds)
        )
      )
    );
  }
}
