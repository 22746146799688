import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { LazyLoadDirective } from './directives/lazy-load.directive';
import { AutofocusDirective } from './directives/autofocus.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ScrollToDirective,
    LoadingSpinnerComponent,
    LazyLoadDirective,
    AutofocusDirective,
  ],
  exports: [
    ScrollToDirective,
    LoadingSpinnerComponent,
    LazyLoadDirective,
    AutofocusDirective,
  ]
})
export class KitModule {}
