import { APP_BOOTSTRAP_LISTENER } from '@angular/core';
import { EnvironmentLoadService } from '../services/environment-load.service';
import { IEnvironment } from '@models';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { getIfExistCookie } from '@helpers/cookie.helper';

const translateCookieInitializerFactory = (environmentLoadService: EnvironmentLoadService<IEnvironment>, http: HttpClient) => () => {
  const cookieConsent = getIfExistCookie('cookieConsent');

  if (cookieConsent && cookieConsent.allowedCookies?.includes('crossDomain')) {
    return;
  }

  http.get<any>(
    `/cms/public/cms-entity/page/${window['websiteConfigs'].cookiePolicyTableSlug || 'cookie-details'}`,
    {
      headers: {
        'accept-language': environmentLoadService.environment.currentStore.language_id,
        'x-store-token': environmentLoadService.environment.currentStore.storeToken
      }
    }
  ).pipe(
    map(res => res?.other?.cookiePolicyModal?.main),
  ).subscribe((res) => window['cookiePolicyConfig'] = res);
}

export const translateCookieInitializerProvider = {
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: translateCookieInitializerFactory,
  multi: true,
  deps: [EnvironmentLoadService, HttpClient]
};
