import { APP_INITIALIZER } from '@angular/core';
import { loadScript } from '@helpers';
import { EnvironmentLoadService } from '../services/environment-load.service';

const factory = (
  environmentLoadService: EnvironmentLoadService
) =>
  () => new Promise(resolve => {
    loadScript(`${environmentLoadService.environment.apiUrl}/tracker/sdk.js`)
      .then(() => {
        resolve(null);
      })
      .catch((err) => {
        console.error(err);
        resolve(null);
      });
  });

export const trackerSdkInitializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: factory,
  deps: [EnvironmentLoadService],
  multi: true
};
