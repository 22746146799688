import { Injectable } from '@angular/core';

@Injectable()
export class SdkLoggingService {
  constructor() {
  }

  logEvent(event: string, context) {
    if (!(window as any).Tracker || !(window as any).Tracker.pushEvent){
      console.warn('No tracker initialised');
      return;
    }

    (window as any).Tracker.pushEvent(event, context);
  }

}
