export function internalAnalyticsHelper(event: string, context = {}) {
  if (!(window as any).apolytics) {
    console.warn('No apolytics initialised');
    return;
  }

  if (Object.keys(context).length) {
    window.dispatchEvent(new CustomEvent(event, {
      detail: { ...context }
    }));
    return;
  }

  window.dispatchEvent(new CustomEvent(event));
}
