export const loadScript = (src: string) => new Promise(resolveCallback(src, document.body));

export const loadScriptToHead = (src: string) => new Promise(resolveCallback(src, document.head));

const resolveCallback = (src: string, elem: HTMLElement) =>
  (resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = (e) => reject(e);
    elem.appendChild(script);
  };
